<template>
    <div class="container py-5">
        <h1>{{$t('adminIndex.heading')}}</h1>
        <p>
            {{$t('adminIndex.subtitle')}}
        </p>
    </div>
</template>

<script>
export default {
    name: 'IndexView'
}
</script>
